export const fieldNames = {
  company: "company",
  email: "email",
  confirmEmail: "confirm-email",
  firstName: "first-name",
  lastName: "last-name",
  street: "street-address",
  additionalAddress: "additional-address",
  zipcode: "postal-code",
  city: "city",
  department: "department",
  currentPassword: "password",
  newPassword: "new-password",
  newPasswordConfirm: "confirm-new-password",
  search: "search",

  creditCard: {
    name: "credit-card-name",
    number: "credit-card-number",
    expiryDate: "credit-card-expiry-date",
    cvc: "credit-card-cvc",
  },

  promoCode: "promo-code",
} as const;
